(function ($) {
 "use strict";
	/*----------------------------
	counterUp js active
	------------------------------ */
	  $('.counter').counterUp({
		delay: 10,
		time: 1000
	});	
 
})(jQuery); 